<mat-drawer-container class="content-container">
  <mat-drawer class="sidepanel" mode="side" opened>
    <ul class="nav-list">
      <img src="../../assets/datastorioLogo.png" class="logo" />
      <li class="nav-item">
        <a class="link" routerLink="../home/dashboard" routerLinkActive="active" ariaCurrentWhenActive="page"
          #dashboard="routerLinkActive">
          <div class="menu-text">
            <div *ngIf="dashboard.isActive">
              <img src="../assets/dashboard-active.svg" />
            </div>
            <div *ngIf="!dashboard.isActive">
              <img class="inactive" src="../assets/dashboard-inactive.svg" />
            </div>
            <p class="menu-name">Dashboard</p>
          </div>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a class="link" routerLink="../home/usage" routerLinkActive="active" ariaCurrentWhenActive="page"
          #usage="routerLinkActive" [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-text">
            <div *ngIf="usage.isActive">
              <img src="../assets/usage-active.svg" />
            </div>
            <div *ngIf="!usage.isActive">
              <img class="inactive" src="../assets/usage-inactive.svg" />
            </div>
            <p class="menu-name">Usage</p>
          </div>
        </a>
      </li> -->
      <!-- <li class="nav-item"> <a class="link" routerLink="../home/status" routerLinkActive="active" ariaCurrentWhenActive="page"
          #status="routerLinkActive" [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-text">
            <div *ngIf="status.isActive">
              <img src="../assets/status-active.svg" />
            </div>
            <div *ngIf="!status.isActive">
              <img class="inactive" src="../assets/status-inactive.svg" />
            </div>
            <p class="menu-name">Status</p>
          </div>
        </a>
      </li> -->
      <li class="nav-item"> <a class="link" routerLink="../home/solutions" routerLinkActive="active"
          ariaCurrentWhenActive="page" #solutions="routerLinkActive" [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-text">
            <div *ngIf="solutions.isActive">
              <img src="../assets/solutions-active.svg" />
            </div>
            <div *ngIf="!solutions.isActive">
              <img class="inactive" src="../assets/solutions-inactive.svg" />
            </div>
            <p class="menu-name">Solutions</p>
          </div>
        </a>
      </li>
      <!--  ## Removed for now ##
          <li class="nav-item"> <a class="link" routerLink="../home/user" routerLinkActive="active" ariaCurrentWhenActive="page"
              #users="routerLinkActive" [routerLinkActiveOptions]="{exact: true}">
              <div class="menu-text">
                <div *ngIf="users.isActive">
                  <img src="../assets/users-active.svg" />
                </div>
                <div *ngIf="!users.isActive">
                  <img class="inactive" src="../assets/users-inactive.svg" />
                </div>
                <p class="menu-name">User</p>
              </div>
            </a>
          </li> -->
      <li class="nav-item"> <a class="link" routerLink="../home/user-management" routerLinkActive="active"
          ariaCurrentWhenActive="page" #usermgmt="routerLinkActive" [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-text">
            <div *ngIf="usermgmt.isActive">
              <img src="../assets/user-mgt-active.svg" />
            </div>
            <div *ngIf="!usermgmt.isActive">
              <img class="inactive" src="../assets/user-mgt-inactive.svg" />
            </div>
            <p class="menu-name">User Mgmt</p>
          </div>
        </a>
      </li>
      <!-- <li class="nav-item"> <a class="link" routerLink="../home/external-auth" routerLinkActive="active"
          ariaCurrentWhenActive="page" #extauth="routerLinkActive" [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-text">
            <div *ngIf="extauth.isActive">
              <img src="../assets/external-auth-active.svg" />
            </div>
            <div *ngIf="!extauth.isActive">
              <img class="inactive" src="../assets/external-auth-inactive.svg" />
            </div>
            <p class="menu-name">External Auth</p>
          </div>
        </a>
      </li> -->
      <div>
        <div class="nav-item logout">
          <div class="link" (click)="logout()">
            <div class="menu-text">
              <img class="inactive" src="../assets/logout.png" />
              <p class="menu-name">Log Out</p>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </mat-drawer>
  <mat-drawer-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <div style="display: inline-flexbox; flex-direction: row;">
          <h1 style="margin-left: 40px; margin-top: 22px;">Welcome Tenant: {{tenantId}}</h1>
        </div>
        <div>
          <a [target]="'_blank'" [href]="environment.KEYCLOAK_URL + '/realms/' + this.tenantId +'/account/#/'">
            <img width="34" height="34" style="margin: 20px 25px 0 0"
              src="../assets/account_circle.png" />
          </a>
        </div>
      </div>
      <!-- <ul class="bread-crumbs">
        <li><a routerLink="../home/dashboard" routerLinkActive="active" ariaCurrentWhenActive="page"
              #dashboard="routerLinkActive">dashboard</a></li>
        <li><a routerLink="../home/dashboard" ariaCurrentWhenActive="page">next</a></li>
      </ul> -->
      <!-- <app-breadcrumb></app-breadcrumb> -->
      <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
