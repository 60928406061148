import { Component, ViewChild, AfterViewInit, OnInit, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {CommonModule} from '@angular/common';
import {MatSlideToggleChange, MatSlideToggleModule} from '@angular/material/slide-toggle';
import {CustomKeycloakAuthService} from '../custom-keycloak-auth.service';
import {MatProgressBarModule} from '@angular/material/progress-bar'
import { Router, RouterLink, RouterModule } from '@angular/router';



export interface Solution{
  solutionId: String,
  name: String,
  action: String|Boolean
  status: String
}



@Component({
  selector: 'app-solutions',
  standalone: true,
  templateUrl: './solutions.component.html',
  imports: [MatButtonModule, MatTableModule, MatSortModule, CommonModule,MatSlideToggleModule, MatProgressBarModule, RouterLink, RouterModule],
  styleUrls: ['./solutions.component.css']
})
export class SolutionsComponent implements AfterViewInit, OnInit{
  solutions$!: Observable<Array<Solution>|any> 
  displayedColumns: string[] = ['solutionName', 'status', 'action'];
  isloading:boolean = true
  public token = sessionStorage.getItem("token") as string;
  public tenantId = sessionStorage.getItem("tenantId") as string;
  data:Solution[]=[]
  dataSource = new MatTableDataSource(this.data);
  //router: any;
  
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService, private customKeycloakAuthService: CustomKeycloakAuthService, public router: Router){
  }

  @ViewChild(MatSort) sort!: MatSort

  async ngOnInit(): Promise<void> {

    // await this.customKeycloakAuthService.initializeKeycloak({ name: this.tenantId, clientId: 'websiteclient', displayName: null })
    const token = await this.customKeycloakAuthService.getToken();
    this.token= token; 
    console.log("Token: " + this.token )
    sessionStorage.setItem("token", token);
    const tokenUpdated = await this.customKeycloakAuthService.updateToken2();
    if (tokenUpdated){
      console.log(`Token Updated`)
      sessionStorage.setItem("token", await this.customKeycloakAuthService.getToken())
    }else{
      console.log(`Token Not Updated`)
    }
    
    this.handleGetSolutions()
  }
    
    
    ngAfterViewInit(): void {
      this.dataSource.sort = this.sort;
  }


  async handleGetSolutions() {
      this.solutions$ = this.apiService.getTenantSolutions(this.token, this.tenantId);
      let solutionArr:Solution[]=[];
      let that = this;
     
      this.solutions$.subscribe({
        next(response) {
          that.data=[]
          that.isloading=false
          response.forEach((solution:Solution) => {
            
            solutionArr.push(solution);
            that.data.push(solution)
          });
          that.dataSource.data=that.data;
        },
        error(err) {
          console.log(err)
        },
      })
  }

   changeStatus(event: MatSlideToggleChange, solutionId: string) {
      let that = this
      this.solutions$ = this.apiService.updateTenantSolutions(this.token,solutionId,this.tenantId, event.checked);
       this.solutions$.subscribe({
        next(response) {
          console.log("respond")
          
          that.handleGetSolutions()
        }})
  }

  viewDetails(value:any) {
    sessionStorage.setItem('isSaved', value);
    // if(value === true) {
    //   this.router.navigate(['./solutions-wizard/dar-settings']);
    // }
  }
}